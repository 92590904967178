import React, { useEffect, useState } from 'react'

import { useMenuState } from '../../context'

import { graphql, navigate, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Oval } from 'react-loader-spinner'
import { convertToBgImage } from 'gbimage-bridge';

import Seo from '../../components/SEO'
import Layout from '../../hoc/Layout'
import YellowButton from '../../components/Buttons/YellowButton'

const UnsubscribePage = ({ pageContext: { locale = 'en' }, data, location }) => {
    const { setMenuOpen } = useMenuState()

    const [msg, setMsg] = useState()
    const [loading, setLoading] = useState(true)

    const heroBg = getImage(data.background.childImageSharp.gatsbyImageData)
    const pageBg = getImage(data.lightBg.childImageSharp.gatsbyImageData)
    const logo = getImage(data.logo.childImageSharp.gatsbyImageData)

    const heroBackground = convertToBgImage(heroBg)
    const pageBackground = convertToBgImage(pageBg)

    const getParameterByName = (name) => {
        /* eslint-ignore-no-useless-escape */
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    const action = getParameterByName("act")
    const endpointId = getParameterByName("id")
    const timestamp = (new Date()).toISOString();

    useEffect(() => {
        if (action === "S") {
            const data = JSON.stringify({
                'endpointId': endpointId,
                'source': location.pathname,
                'optTimestamp': timestamp.toString(),
                'optOutAll': false,
                'action': 'S'
            });
            subscribe(data)
        }

        if (action === "U") {
            const data = JSON.stringify({
                'endpointId': endpointId,
                'source': location.pathname,
                'optTimestamp': timestamp.toString(),
                'optOutAll': true,
                'action': 'U'
            });
            unSubscribe(data);
        }

        if (!action) {
            navigate('/');
        }
    }, [action, location.pathname, timestamp, endpointId])

    useEffect(() => {
        setMenuOpen(false);
    }, [location, setMenuOpen]);

    const subscribe = async (data) => {
        await fetch('https://fk9m8pinh9.execute-api.eu-west-1.amazonaws.com/v1/email', {
            method: 'POST',
            body: data,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((res) => res.json()).then((json) => {
            setLoading(false);
            setMsg('Thanks for Signing up!');
        }).catch((error) => {
            setLoading(false);
            setMsg('An error occured while confirming. Please try again later.')
        });
    }

    const unSubscribe = async (data) => {
        await fetch('https://fk9m8pinh9.execute-api.eu-west-1.amazonaws.com/v1/email', {
            method: 'POST',
            body: data,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((res) => res.json()).then((json) => {
            setLoading(false);
            setMsg('You have been unsubscribed.');
        }).catch((error) => {
            setLoading(false);
            setMsg('An error occured. Please try again later.');
        })
    }

    return (
        <Layout locale={locale}>
            <BackgroundImage
                tag="section"
                {...heroBackground}
                className="stacking-context-9"
                style={{
                    backgroundPosition: 'center'
                }}>
                <div className="min-h-[500px] md:h-[625px] lg:min-h-[700px] flex items-center justify-center relative">
                    <GatsbyImage image={logo} alt={"The Cycle: Frontier Logo"} className="max-w-[450px] w-10/12 mx-auto" />
                    <div className="bg-darks5 bg-opacity-50 absolute w-full h-full z-[-10]" />
                </div>
            </BackgroundImage>

            <BackgroundImage
                tag="section"
                {...pageBackground}
                className="stacking-context-8"
                style={{
                    backgroundRepeat: 'repeat',
                    backgroundSize: '800px'
                }}>
                <div className="min-h-[40vh] py-20">
                    <div className="py-12 lg:py-16 lg:grid lg:grid-cols-18 text-darks1">
                        <div className="font-display my-4 col-start-2 col-end-17 lg:col-start-4 lg:col-end-16 max-w-screen-lg mx-auto w-11/12 flex items-center justify-center flex-col text-center">
                            {loading ? (
                                <>
                                    <h1 className="uppercase text-5xl mb-8">Your request is being handled...</h1>
                                    <Oval
                                        color="#79c6f2"
                                        secondaryColor='#121c25'
                                        height={50}
                                        width={50}
                                    />
                                </>
                            ) : (
                                <>
                                    <h1 className="uppercase text-5xl mb-8">{msg}</h1>
                                    <YellowButton link={'/'} text="Home" />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </BackgroundImage>
        </Layout>
    )
}
export default UnsubscribePage

export const query = graphql`
    query UnsubscribePage {
        logo: file(relativePath: { eq: "images/the-cycle-logo-final-bw-horizon2_white.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        background: file(relativePath: {eq: "images/season-1-wallpaper.jpg"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        lightBg: file(
            relativePath: { eq: "images/textures/texture-img-light.png" }
        ) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
    }
`